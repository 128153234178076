import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import get from 'lodash/get'
import find from 'lodash/find'
import Cookies from 'js-cookie'

import Block from '@components/block'
import MailingListModal from '@components/mailing_list_modal'
import Footer from '@components/footer'
import Header from '@components/header'
import PageHead from '@components/layout/page_head'
import { GlobalContextProvider } from '@util/global_context'

const DefaultLayout = dynamic(() => import('@components/layout/default_layout'))
const HomepageLayout = dynamic(() => import('@components/layout/homepage_layout'))
const PlainLayout = dynamic(() => import('@components/layout/plain_layout'))
const StandardLayout = dynamic(() => import('@components/layout/standard_layout'))

const layouts = {
  Homepage: HomepageLayout,
  Standard: StandardLayout,
  Legal: StandardLayout,
  Plain: PlainLayout,
  Default: DefaultLayout,
}

const NoSsrSiteBanner = dynamic(() => import('@components/site_banner'), { ssr: false })
const dismissSiteBanner = () => Cookies.set('banner-dismissed', true, { expires: 180 })

const PageLayout = props => {
  const [mailingListModalIsOpen, setMailingListModalIsOpen] = useState(false)
  const LayoutComponent = layouts[props.page.fields.layout] || PlainLayout
  const blocks = props.page.fields.blocks
  const path = props.page.fields.path
  const globalContext = props.globalContext

  const header = find(globalContext.menus, m => get(m, 'fields.name') === 'Header Menu')
  const headerItems = get(header, 'fields.menuItems', [])

  const footer = find(globalContext.menus, m => get(m, 'fields.name') === 'Footer Menu')
  const footerItems = get(footer, 'fields.menuItems', [])

  const showSiteBanner = get(globalContext, 'settings.fields.showSiteBanner', false)
  const siteBanner = get(find(globalContext.siteBanners, ['fields.active', true]), 'fields')

  const newsletterIframeUrl = get(globalContext, 'settings.fields.mailchimpSignupUrl')

  const siteBannerDismissed = Cookies.get('banner-dismissed')

  return (
    <div className="rootWrapper">
      <PageHead page={props.page} globalContext={props.globalContext} />
      <GlobalContextProvider
        value={{
          ...globalContext,
          setMailingListModalIsOpen,
        }}
      >
        <MailingListModal
          isOpen={mailingListModalIsOpen}
          onClose={() => setMailingListModalIsOpen(false)}
          iframeUrl={newsletterIframeUrl}
        />
        <div className="rootWrapper__bg" />
        <Header
          siteBanner={{ showSiteBanner: showSiteBanner && !siteBannerDismissed, ...siteBanner }}
          currentPath={path}
          menuItems={headerItems}
        />

        <main className="rootContent">
          <LayoutComponent key={props.globalContext.currentPathname}>
            {blocks.map((block, i) => (
              <Block key={`block-${i}`} block={block} />
            ))}
          </LayoutComponent>
        </main>

        <Footer menuItems={footerItems} />

        {showSiteBanner && !siteBannerDismissed && (
          <NoSsrSiteBanner
            onClose={dismissSiteBanner}
            bannerText={siteBanner.bannerText}
            ctaLink={siteBanner.ctaLink}
            ctaText={siteBanner.ctaText}
          />
        )}
      </GlobalContextProvider>
    </div>
  )
}

export default PageLayout
