import React from 'react'
import classNames from 'classnames'

import styles from '@components/icons/close.module.scss'

const Close = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.closeIcon, className)}
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <circle className={styles.closeIcon__background} cx="18.5" cy="18.5" r="18.5" />
        <path
          d="M18.84 16.68l3.22-4.8c.52-.9 1.24-1.3 1.9-1.3 1.28 0 2.36 1.16 2.23 1.4l-4.94 6.8 4.93 6.74c.12.25-.98 1.42-2.3 1.42-.7 0-1.47-.35-2-1.27l-3.18-4.86-3.24 4.9c-.58.97-1.27 1.3-1.95 1.3-1.24 0-2.34-1.18-2.2-1.43l4.97-6.83-4.86-6.7c-.15-.25 1-1.44 2.32-1.44.74 0 1.44.37 1.97 1.3l3.1 4.78z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default Close
