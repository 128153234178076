import React from 'react'
import dynamic from 'next/dynamic'

// Lazily-load all the components so the entire dependency tree doesn't get
// pulled into the homepage bundle
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading
const CalloutBlock = dynamic(() => import('@components/blocks/callout_block'))
const ContactFormBlock = dynamic(() => import('@components/blocks/contact_form_block'))
const CtaBlock = dynamic(() => import('@components/blocks/cta_block'))
const EntryPoint = dynamic(() => import('@components/blocks/entry_point'))
const EntryPointCardList = dynamic(() => import('@components/blocks/entry_point_card_list'))
const HeaderBlock = dynamic(() => import('@components/blocks/header_block'))
const HeroBlock = dynamic(() => import('@components/blocks/hero_block'))
const HomepageHeroBlock = dynamic(() => import('@components/blocks/homepage_hero_block'))
const LargeVideoWithContent = dynamic(() => import('@components/blocks/large_video_with_content'))
const PreviewVroomTips = dynamic(() => import('@components/blocks/preview_vroom_tips'))
const PrintableMaterials = dynamic(() => import('@components/blocks/printable_materials'))
const ProgrammaticBlock = dynamic(() => import('@components/programmatic_block'))
const QuoteBlock = dynamic(() => import('@components/blocks/quote_block'))
const SubheaderBlock = dynamic(() => import('@components/blocks/subheader_block'))
const Subnavigation = dynamic(() => import('@components/blocks/subnavigation'))
const TestimonialsBlock = dynamic(() => import('@components/blocks/testimonials_block'))
const TextAndImageBlock = dynamic(() => import('@components/blocks/text_and_image_block'))
const TextAndImageCarousel = dynamic(() => import('@components/blocks/text_and_image_carousel'))
const TextBlock = dynamic(() => import('@components/blocks/text_block'))
const TextMediaBlock = dynamic(() => import('@components/blocks/text_media_block'))
const ThreeColumnsBlock = dynamic(() => import('@components/blocks/three_columns_block'))
const TypeformEmbed = dynamic(() => import('@components/blocks/typeform_embed'))
const VideoCarouselBlock = dynamic(() => import('@components/blocks/video_carousel_block'))
const VideoList = dynamic(() => import('@components/blocks/video_list'))

class UnknownBlock extends React.Component {
  render() {
    return (
      <div>
        <p>Unknown Block</p>
        <pre>{JSON.stringify(this.props, null, 2)}</pre>
      </div>
    )
  }
}

// Map Contentful graphql names to internal React components
const BlockTypeMap = {
  CalloutBlock,
  EntryPoint,
  EntryPointCardList,
  TextBlock,
  HeroBlock,
  CtaBlock,
  QuoteBlock,
  ThreeGridImagesBlock: ThreeColumnsBlock,
  ProgrammaticBlock: ProgrammaticBlock,
  TextMediaBlock,
  HomepageHeroBlock,
  VideoCarouselBlock,
  ContactFormBlock,
  ColumnImagesTextBlock: ThreeColumnsBlock,
  Subnavigation,
  HeaderBlock,
  SubheaderBlock,
  VideoList,
  PreviewVroomTips,
  LargeVideoWithContent,
  TestimonialsBlock,
  TextAndImageBlock,
  TextAndImageCarousel,
  TypeformEmbed,
  PrintableMaterials,
}

const colors = {
  tan: '#FAF5E6',
  blue: '#D7F5F5',
  orange: '#FC9700',
  white: '#FFFFFF',
  bluePattern: '#D7F5F5',
}

class Block extends React.Component {
  blockRef = React.createRef()
  wrapperRef = React.createRef()

  getColorStop() {
    const block = this.blockRef.current

    if (block && block.getPageBackgroundColor != null) {
      const colorName = block.getPageBackgroundColor()
      const color = colors[colorName]

      if (color) {
        return { color, colorName, el: this.wrapperRef.current }
      }
    }

    return null
  }

  render() {
    const { block, locale } = this.props
    const fields = (block && block.fields) || {}

    if (block === undefined || block.type === undefined) return null

    const blockTypeName = block.type || 'unknown'
    const BlockType = BlockTypeMap[blockTypeName] || UnknownBlock

    if (fields.visible === false) return null

    return (
      <div ref={this.wrapperRef} className={'block ' + blockTypeName}>
        <BlockType lazyRef={this.blockRef} fields={fields} blockId={block.id} locale={locale} />
      </div>
    )
  }
}

export default Block
