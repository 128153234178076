import React from 'react'
import Dialog from '@mui/material/Dialog'

import { withGlobalContext } from '@util/global_context'
import LoadingIcon from '@components/icons/loading'
import CloseIcon from '@components/icons/close'

import styles from '@components/mailing_list_modal.module.scss'

const MailingListModal = props => {
  const { iframeUrl, onClose, isOpen } = props

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      classes={{ paper: `${styles.materialModal} mcEmail` }}
      keepMounted={false}
    >
      <div className={styles.mailingListModal__loading}>
        <LoadingIcon />
      </div>
      <div className={styles.materialModal__close}>
        <CloseIcon onClick={onClose} />
      </div>
      <div style={{ width: '100%', height: '100%' }}>
        <iframe src={iframeUrl} title="mc-signup" className={styles.signupFrame} />
      </div>
    </Dialog>
  )
}

export default withGlobalContext(MailingListModal)
