import React from 'react'

import Layout from '@components/layout'
import loadPageProps from '@util/loadPageProps'
import { useLocale } from '@util/useLocale'
import { ViewportProvider } from '@util/viewport'
import { getRedirectsByLocale, getInternalPaths } from '@cms-service/api'

const DynamicPage = ({ page, globalContext }) => {
  useLocale(globalContext)

  if (page) {
    return (
      <ViewportProvider>
        <Layout page={page} globalContext={globalContext} />
      </ViewportProvider>
    )
  }
}

export async function getStaticPaths() {
  const paths = (await getInternalPaths())
    .filter(pathIsNotLinkCommand)
    .filter(pathHasNoFragment)
    .filter(pathIsNotRoot)
    .map(pathEntityToLocalizedStrings)
    .reduce((pathStringAcc, pathVariants) => [...pathStringAcc, ...pathVariants], [])
    .filter(pathHasLeadingSlash)

  return {
    paths,
    fallback: true,
  }
}

const pathEntityToLocalizedStrings = pathEntity =>
  Object.keys(pathEntity.fields.path).map(locale =>
    locale === 'en-US'
      ? pathEntity.fields.path[locale]
      : `/${locale}${pathEntity.fields.path[locale]}`
  )

const linkCommandPrefix = '__'
const pathIsNotLinkCommand = pathEntity =>
  !getDefaultFieldValue(pathEntity.fields.path).startsWith(linkCommandPrefix)
const pathHasNoFragment = pathEntity => !getDefaultFieldValue(pathEntity.fields.path).includes('#')

const getDefaultFieldValue = entityField => entityField['en-US']
const pathIsNotRoot = pathEntity => getDefaultFieldValue(pathEntity.fields.path) !== '/'
const pathHasLeadingSlash = path => path.slice(0, 1) === '/'

export async function getStaticProps({ params: { slug } }) {
  const redirectsResult = await getRedirectsByLocale('all')
  const redirects = redirectsResult || []

  const path = '/'.concat(slug.join('/'))
  const locale = slug[0] === 'es' ? slug[0] : undefined
  const pathWithoutLocale = slug[0] === 'es' ? '/'.concat(slug.slice(1).join('/')) : path

  const redirectLocale = locale || 'en-US'
  const redirect = redirects.find(
    redirect => redirect.fields.path[redirectLocale] === pathWithoutLocale
  )

  if (redirect) {
    return {
      redirect: {
        destination: redirect.fields.redirectValue['en-US'],
        permanent: true,
      },
    }
  }

  const props = await loadPageProps({ slug: pathWithoutLocale, locale })

  if (!props.page) {
    return { notFound: true }
  }

  return { props }
}

export default DynamicPage
