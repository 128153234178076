import React from 'react'

import styles from '@components/icons/loading.module.scss'

const Loading = () => {
  return (
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" className={styles.loadingIcon}>
      <circle
        className="circleIcon__path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  )
}

export default Loading
